import { useEffect, useRef, useState } from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";

export function ReviewWidget({ lightToken, darkToken, children, ...rest }) {
  const ref = useRef();
  const romwToken = useColorModeValue(lightToken, darkToken);
  const [isLoaded, setIsLoaded] = useState(false);

  // Load a different widget baed on the color mode
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://reviewsonmywebsite.com/js/v2/embed.js?id=7bf8acda5d5930b1a9db343a4ec1b31`;
    script.type = "text/javascript";
    ref.current.appendChild(script);

    return () => {
      setIsLoaded(false); // Reset the loaded state when color mode changes
      if (ref.current) {
        ref.current.querySelector("iframe")?.remove();
        ref.current.querySelector("script")?.remove();
        ref.current.removeAttribute("data-romw-initialized");
        ref.current.removeAttribute("data-romw-widget-index");
      }
      delete window.romwLoader;
      delete window.romwWidgetIndex;
      delete window.romwInitializedDivId;
    };
  }, [romwToken]);

  // Watch the nested iframe that ROWM injects to determine when it's loaded
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const { target, type, attributeName, oldValue } = mutation;

        const isLoaded =
          target.tagName.toLowerCase() === "iframe" &&
          type === "attributes" &&
          attributeName === "style" &&
          oldValue.includes("overflow");

        isLoaded && setIsLoaded(isLoaded);
      });
    });

    observer.observe(ref.current, {
      subtree: true,
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ["style"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Box ref={ref} data-romw-token={romwToken} {...rest}>
      {/*
        `children` contains the loading skeleton
        ROMW will append an iframe widget into this div
       */}
      {isLoaded ? null : children}
    </Box>
  );
}
